<script>
  import Header from "../components/Header.svelte";
</script>

<Header title="Page Not Found" />

<main>
  <p>Sorry, something went wrong.</p>
</main>

<div class="btn-container">
  <a class="btn btn--primary" href="https://druidry.org"
    >Back to the OBOD website</a
  >
</div>
